import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Pranjal Pandey</h2>
        {/* <p><a href="mailto:itspranjalpandey@gmail.com">itspranjalpandey@gmail.com</a></p> */}
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Pranjal. I am an <a href="https://icme.stanford.edu/">IIT Jodhpur</a> graduate,
        and Founder at <a href="https://corpusvision.com">Corpusvision</a>.
        I am a Fullstack / Flutter Developer and Devops Engineer.<br /><br />
        I am currently exploring Artificial Intelligence, Machine Learning,
        and honing my skills in Data Structures and Algorithms.
      </p>
      <br />
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Pranjal Pandey <Link to="/">ipranjal.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
