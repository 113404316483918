const routes = [
  {
    index: true,
    label: 'Pranjal Pandey',
    path: '/',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: 'Freelancing',
    path: 'https://www.upwork.com/freelancers/~013c7d340787747b2c',
  },
  {
    label: 'Github',
    path: 'https://www.github.com/ipranjal',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
